// document.addEventListener('alpha.loaded', (e) => {
//   const alphaModuleMgr = e.detail.emitter
//   // listen to viewport changes and move secondary menu to top bar / collapsed mobile menu if necessary
//   const desktopBreakpoint = alphaModuleMgr.getConfig().desktopBreakpoint
//   // const mql = window.matchMedia('(min-width: 1600px)')
//   alphaModuleMgr.addModuleEventListener('viewport', 'changed', (e) => {
//     if (e.viewport.is(`>=${desktopBreakpoint}`)) {
//       const initialLocation = navSvgInnerEl.closest(navPartLeft) !== null
//       if (!initialLocation) {
//         navPartLeftEl.appendChild(navSvgInnerEl)
//       }
//     } else {
//       const inMenu = navSvgInnerEl.closest(parentSelector) !== null
//       if (!inMenu) {
//         navMainParentEl.appendChild(navSvgInnerEl)
//       }
//     }
//   })
// })

document.addEventListener('alpha.loaded', (e) => {
  const mql = window.matchMedia('(max-width: 992px)')
    const ReplacementWrapperEl = document.createElement('div');
  const videoEl = document.querySelector('.featured-image .cloudflare-video-block')
  ReplacementWrapperEl.innerHTML = '<div class="featured-image-mobile-wrapper vh-50 vw-100">' +
    '</div>' +
    '<div class="youtube-interview">' +
    '<iframe src="https://www.youtube.com/embed/iiDq1dPmRkA" title="YouTube video player"' +
            ' allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"' +
            ' allowFullScreen></iframe>'
    '</div>'
  if (mql.matches) {
    videoEl.replaceWith(ReplacementWrapperEl)
    console.log(ReplacementWrapperEl.outerHTML);
  }
})

